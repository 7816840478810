import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { GeneralPaymentDetails, InitiateSchoolFeePayment } from "../../api/student";
import SchoolFeeComponent from "../../components/SchoolFeeComponent";
import Footer from "../../components/Footer";
import { getCurrentUser } from "../../utils/authService";
import { toast } from "react-toastify";
import usePayments from "../../hooks/usePayments";
import api from "../../api/api";

const SchoolFees = () => {
  const user = getCurrentUser();
  const [schoolFee, setSchoolFee] = useState();
  const [loading, setLoading] = useState(false)
  const [paymentType, setPaymentType] = useState("")
  const [paymentLevel, setPaymentLevel] = useState("")
  const [paymentSession, setPaymentSession] = useState("")
  const [btnText, setBtnText] = useState("Proceed to payment");
  const {payment} = usePayments()
  

  const getPaymentDetails = async () => {
    if (!paymentType || !paymentLevel || !paymentSession) return
    try {
      const { data } = await api.post('/student/generate-payment', {
        payment_name: paymentType,
        payment_level: paymentLevel,
        payment_session: paymentSession
      });
      if (data.status === "error") {
        toast.error(data.message);
        setPaymentSession('')
      }
      setSchoolFee(data.data);
    } catch (error) {}
  }

  const paySchoolFee = async () => {
    try {
        setBtnText("Initializing...")
      setLoading(true);
      const { data } = await InitiateSchoolFeePayment({
        student_id: user,
        payment_option: paymentType,
        payment_level: paymentLevel,
        payment_session: paymentSession
      });
      console.log("payment oh", data);
      const {status, authorization_url, reference} = data
      if (status && status === "success") {
        toast.info("Payment already made")
        setLoading(false)
        setBtnText("Proceed to payment")
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };


  useEffect(() => {
    getPaymentDetails()
  }, [paymentType, paymentLevel, paymentSession]);
  return (
    <>
      <div class="page-wrapper">
        {(payment) && (
          <div class="page-content-tab">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <div class="float-right">
                      <ol class="breadcrumb">
                        {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                        <li class="breadcrumb-item active">Payment</li>
                      </ol>
                    </div>
                    <h4 class="page-title">Payment</h4>
                  </div>
                  {(payment?.school_fees_payment?.payment_status !== 'success') && 
                  <SchoolFeeComponent
                    loading={loading}
                    btnText={btnText}
                    schoolFee={schoolFee}
                    handlePayment={paySchoolFee}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    paymentLevel={paymentLevel}
                    setPaymentLevel={setPaymentLevel}
                    paymentSession={paymentSession}
                    setPaymentSession={setPaymentSession}
                  />
                 } 
                    {(payment?.school_fees_payment?.payment_status === 'success') &&
                    <div class="alert dspg-success" role="alert">
                    payments completed.
                  </div>
                    }
                </div>
              </div>
            </div>
            <Footer />
          </div>
         )} 
      </div>
    </>
  );
};

export default SchoolFees;
